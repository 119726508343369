<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Edit Item" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Key *"
                  v-model.number="key"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.key"
                  :error="!!errors.key"
                  :error-messages="errors.key"
                />
                <va-input
                  label="Header *"
                  v-model="header"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.header"
                  :error="!!errors.header"
                  :error-messages="errors.header"
                />
                <va-input
                  label="Name *"
                  v-model="name"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.name"
                  :error="!!errors.name"
                  :error-messages="errors.name"
                />
                <div style="margin-bottom: 15px;">
                  <span>Description *</span>
                  <ckeditor v-model="desc" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-input
                  label="Name *"
                  v-model="name"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.name"
                  :error="!!errors.name"
                  :error-messages="errors.name"
                />
                <va-card class="mb-3" title="Image *">
                  <div v-if="imgExisting">
                    <a style="display: block;" target="_blank" :href="imgExisting"><img style="max-width: 400px;" :src="imgExisting"></a>
                    <div><va-button small color="danger" @click="deleteImage()">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    @input="delete errors.img"
                    type="gallery"
                    dropzone
                    v-model="img"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.img" style="color: red;">{{ this.errors.img[0] }}</div>
                  <va-button @click="uploadImage(img[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="linkHref *"
                  v-model="linkHref"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.linkHref"
                  :error="!!errors.linkHref"
                  :error-messages="errors.linkHref"
                />
                <va-input
                  label="linkName *"
                  v-model="linkName"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.linkName"
                  :error="!!errors.linkName"
                  :error-messages="errors.linkName"
                />
                <va-toggle label="Published" small v-model="published"/>
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {

      key: '',
      header: '',
      name: '',
      linkName: '',
      linkHref: '',
      text_contents_text: '',
      trailingText: '',
      stats_text: '',
      desc: null,
      highlighted: null,
      stats: null,
      companyContents: null,

      img: [],
      imgId: '',
      imgExisting: '',
      image: null,
      minicardImg: [],
      minicardImgId: '',
      minicardImgExisting: '',
      label: '',
      alias: '',
      published_at: '08-05-2020 14:10:00',
      position: '',
      published: false,

      seoId: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      seoText: '',
      seoImage: [],
      seoImageId: '',
      seoImageExisting: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_AUTOSTRADA_API_URL}/uk/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      sign: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    if (!this.$attrs.id) {
      const today = new Date()
      this.published_at = today.toLocaleDateString('uk-UA') + ' ' + today.toLocaleTimeString('uk-UA')
      const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
      this.sign = generateRandomString(32)
    }
    this.fetch()
  },
  methods: {
    uploadImage (file) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      axios.post(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.image = response.data.patch
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage () {
      this.imgExisting = ''
      this.imageNew = null
    },
    submit: function () {
      const data = {
        key: this.key,
        image: this.image,
        published: !!this.published,
        header: this.header,
        name: this.name,
        desc: this.desc,
        linkHref: this.linkHref,
        linkName: this.linkName,
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/items/${this.$attrs.id}`, data)
          .then(response => {
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/items`, data)
          .then(response => {
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$attrs.id) {
        axios.get(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/items/${this.$attrs.id}`)
          .then(response => {
            console.log(response.data)
            this.key = response.data.key
            this.image = response.data.image
            this.imgExisting = process.env.VUE_APP_BGK_URL + response.data.image
            this.published = !!response.data.published
            this.header = response.data.header
            this.name = response.data.name
            this.desc = response.data.desc
            this.linkHref = response.data.linkHref
            this.linkName = response.data.linkName
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    }
    ,
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
